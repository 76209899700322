import { Trans, useTranslation } from 'next-i18next';
import { Box } from 'ui';

import { Card } from './Card';
import { CardImage } from './CardImage';
import { CardTitle } from './CardTitle';
import { SubTitle } from './SubTitle';

export const TokiClientCard = () => {
  const { t } = useTranslation();

  return (
    <Box px={2}>
      <Card background={{ backgroundColor: 'white' }}>
        <Box mb={4} p={2}>
          <CardImage src="/images/user.png" />
        </Box>

        <Box display="inline" mb={2}>
          <Trans
            i18nKey="home:becomeClientCard.title"
            components={[<CardTitle />, <CardTitle color="primary.main" />]}
          />
        </Box>

        <Box mb={2}>
          <SubTitle>{t('home:becomeClientCard.subtitle')}</SubTitle>
        </Box>
      </Card>
    </Box>
  );
};
