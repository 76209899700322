import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { BillingPlans } from 'types';
import { Box, Button, Card, CardContent, CardHeader, Typography, useResolution } from 'ui';

import { PlanDetail } from './PlanDetail';

interface Props {
  title: string;
  subtitle?: string;
  details: string[];
  price: string;
  promoPrice?: string;
  isGreen: boolean;
  billingPlan: keyof typeof BillingPlans;
  baseUrl: string;
  additionalInfo?: string;
}

export const PlanCard = ({
  title,
  subtitle,
  details,
  price,
  promoPrice,
  isGreen,
  billingPlan,
  baseUrl,
  additionalInfo,
}: Props) => {
  const { t } = useTranslation('plans');
  const { push } = useRouter();
  const { isTablet } = useResolution();

  return (
    <Box display="flex" flexDirection="column">
      <Card sx={{ alignSelf: 'center', borderRadius: 4, minHeight: isTablet ? 540 : undefined, p: 2, width: '95%' }}>
        <CardHeader
          title={
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h5" fontWeight="bold">
                {title}
              </Typography>

              {promoPrice && (
                <Box
                  border={1}
                  px={2}
                  pt={0.5}
                  borderColor="purple.main"
                  borderRadius={8}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="body2" fontWeight={600} color="purple.main" component="span">
                    {t('promo').toUpperCase()}
                  </Typography>
                </Box>
              )}
            </Box>
          }
          subheader={
            <Typography variant="h5" fontWeight="bold" color={isGreen ? 'primary.main' : 'secondary.main'}>
              {subtitle}
            </Typography>
          }
        />
        <CardContent sx={{ pt: 0 }}>
          {details.map((detail) => (
            <PlanDetail key={detail} text={detail} color={isGreen ? 'primary.main' : 'secondary.main'} />
          ))}
        </CardContent>
      </Card>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{
          backgroundColor: isGreen ? 'primary.main' : 'secondary.main',
          borderRadius: 4,
          height: 140,
          mt: -2,
          p: 2,
        }}
      >
        <Typography variant="h5" color="common.white" mb={1} noWrap>
          {price}
        </Typography>

        {additionalInfo && (
          <Box mb={1}>
            <Typography variant="body2" color="common.white" noWrap>
              {additionalInfo}
            </Typography>
          </Box>
        )}

        <Box>
          <Button
            color={isGreen ? 'white' : 'secondaryWhite'}
            variant="secondary"
            size="md"
            onClick={() => push(`${baseUrl}?plan=${billingPlan}`)}
          >
            {t('choosePlan')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
