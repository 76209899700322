import { Box, Typography } from 'ui';
import { CheckCircle } from 'ui/icons';

interface Props {
  text: string;
  color: string;
}

export const PlanDetail = ({ text, color }: Props) => {
  return (
    <Box display="flex" mb={1}>
      <CheckCircle fontSize="medium" sx={{ color }} />
      <Typography variant="subtitle1" sx={{ ml: 2 }}>
        {text}
      </Typography>
    </Box>
  );
};
